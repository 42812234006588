.tableWrapper {
  width: 100%;
}

.table {
  width: 100%;
  border-collapse: collapse;
}

.table,
.tableHeader,
.tableData,
.emptyStateWrapper {
  border: 1px solid var(--color-table-border);
}

.emptyStateWrapper {
  border-radius: var(--border-radius-base);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.tableHeader {
  padding: var(--spacing-16);
  text-align: start;
}

.tableData {
  padding: var(--spacing-08) var(--spacing-16);
  width: 50%;
}
